import {
    CreateSourceModel as BaseCreateSourceModel,
    LightSourceModel as BaseLightSourceModel,
    RawSourceConfig as BaseRawSourceConfig,
    SourceFieldModel as BaseSourceFieldModel,
    SourceModel as BaseSourceModel,
} from '@coveo/platform-client';

// @experimental do not move to PlatformClient
export enum SourceCategory {
    CLOUD = 'CLOUD',
    CRAWLING_MODULE = 'CRAWLING_MODULE',
    ON_PREM = 'ON_PREM',
    PUSH = 'PUSH',
}

// @experimental do not move to PlatformClient
export enum SourceType {
    ADOBE_EXPERIENCE_MANAGER = 'ADOBE_EXPERIENCE_MANAGER', // @deprecated in PlatformService
    AMAZONS3 = 'AMAZONS3',
    BOX_ENTERPRISE2 = 'BOX_ENTERPRISE2',
    CATALOG = 'CATALOG',
    CONFLUENCE = 'CONFLUENCE', // @deprecated in PlatformService
    CONFLUENCE2 = 'CONFLUENCE2',
    CONFLUENCE2_HOSTED = 'CONFLUENCE2_HOSTED',
    CUSTOM = 'CUSTOM',
    DATABASE = 'DATABASE',
    DROPBOX_FOR_BUSINESS = 'DROPBOX_FOR_BUSINESS',
    FILE = 'FILE',
    GENERIC_REST = 'GENERIC_REST',
    GOOGLE_DRIVE_DOMAIN_WIDE = 'GOOGLE_DRIVE_DOMAIN_WIDE',
    GRAPHQL = 'GRAPHQL',
    JIRA2 = 'JIRA2',
    JIRA2_HOSTED = 'JIRA2_HOSTED',
    JIVE = 'JIVE',
    JIVE_HOSTED = 'JIVE_HOSTED',
    KHOROS = 'KHOROS',
    LITHIUM = 'LITHIUM',
    MICROSOFT_DYNAMICS = 'MICROSOFT_DYNAMICS',
    PUSH = 'PUSH',
    RSS = 'RSS',
    SALESFORCE = 'SALESFORCE',
    SALESFORCE_SITES = 'SALESFORCE_SITES',
    SAP = 'SAP',
    SERVICENOW = 'SERVICENOW',
    SHAREPOINT = 'SHAREPOINT',
    SHAREPOINT_ONLINE2 = 'SHAREPOINT_ONLINE2',
    SITECORE = 'SITECORE',
    SITEMAP = 'SITEMAP',
    SLACK = 'SLACK',
    TWITTER2 = 'TWITTER2',
    WEB2 = 'WEB2',
    YOUTUBE = 'YOUTUBE',
    ZENDESK = 'ZENDESK',
}

// @experimental do not move to PlatformClient
export interface CreateSourceModel extends Omit<BaseCreateSourceModel, 'sourceType'> {
    sourceType?: SourceType;
}
// @experimental do not move to PlatformClient
export interface LightSourceModel extends Omit<BaseLightSourceModel, 'sourceType'> {
    sourceType?: SourceType;
}
// @experimental do not move to PlatformClient
export interface RawSourceConfig extends Omit<BaseRawSourceConfig, 'sourceType'> {
    sourceType?: SourceType;
}
// @experimental do not move to PlatformClient
export interface SourceModel extends Omit<BaseSourceModel, 'sourceType'> {
    sourceType?: SourceType;
}
// @experimental do not move to PlatformClient
export interface SourceFieldModel extends Omit<BaseSourceFieldModel, 'sources'> {
    sources?: LightSourceModel[];
}

export interface BasicSourceModel {
    /**
     * The unique identifier of the source.
     */
    id: string;
    /**
     * The name of the source.
     */
    name: string;
    /**
     * The type of the source.
     */
    sourceType: SourceType;
}
